body {
  background-color: #111111;
}

.mine__root {
  background-color: #111111;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mine__container {
  margin: 2vh 0;
}

.mine__container > h2 {
  color: #ffffff;
  text-align: center;
}

.block__row {
  display: flex;
  justify-content: space-evenly;
  margin: 2vh 0;
  text-align: center;
}

.block__col {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.mine__block {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-evenly;
  border: 2px solid whitesmoke;
  color: #fff;
  border-radius: 1%;
  object-fit: contain;
  height: 350px;
  width: 400px;
  padding: 15px;
  margin: 2vh 20rem;
  cursor: pointer;
}

.mine__block:hover {
  box-shadow: 0 0 10px 10px rgba(220, 220, 220, 0.1);
}

.mine__block > h3, .block__col > h3, .block__row > h3 {
  text-decoration: underline;
}

.mine__block > h3, h4, h5 {
  text-align: center;
  margin-top: 1rem;
}

.mine__block > h5 {
  color: mediumpurple;
}

.mine__block:hover > .block__col > h5 {
  color: rgba(255, 255, 255, 10%)!important;
}

.mine__block__overlay {
  position: relative;
  object-fit: contain;
  top: -50%;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: none;
  backdrop-filter: grayscale(10px) blur(10px);
  color: mediumpurple;
  font-size: 1.5rem;
  margin: auto auto;
  font-weight: bold;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  -ms-transition: all 2s ease-out;
  transition: all .5s ease-out;
}

.mine__no__transactions {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 3vh 0;
}

.mine__no__transactions > h2 {
  color: mediumpurple;
  margin: 7vh 0;
}

.mine__no__transactions > button {
  margin: 7vh 0;
  padding: 10px;
  border-radius: 10px;
  background-color: mediumpurple;
  color: whitesmoke;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
}

.mine__no__transactions:hover > button:hover {
  background-color: darkviolet;
}

.mine__block__success {
  position: relative;
  object-fit: contain;
  top: -50%;
  align-self: center;
  text-align: center;
  justify-content: center;
  display: none;
  backdrop-filter: grayscale(10px) blur(10px);
  color: limegreen;
  font-size: 1.5rem;
  margin: auto auto;
  font-weight: bold;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  -ms-transition: all 2s ease-out;
  transition: all .5s ease-out;
}