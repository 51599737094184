@keyframes fadeOut {
  from {
    opacity: 1;
    pointer-events: all;
  }
  to {
    opacity: 0;
    pointer-events: none;
  }
}

.transact__root {
  width: 100%;
  height: 100%;
  background-color: #111111;
}

.transact__container {
  margin: 0 15vw;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  color: mediumslateblue;
}

.transact__container > input {
  height: 5vh;
  margin: 4vh 0;
  border-radius: 10px;
  color: black;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0 1vw;
}

.transact__container > input:active, input:focus, input::selection {
  text-decoration: none;
  outline: none;
}

.transact__button {
  background-color: mediumslateblue;
  color: whitesmoke;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  padding: 1vh 0;
  width: 50%;
  align-self: center;
  margin: 8vh;
}

.transact__button:hover {
  background-color: darkblue;
  outline: none;
}

.transaction__message {
  color: #111111;
  padding: 5px;
  text-align: center;
  margin-top: 0;
  align-items: center;
  justify-content: center;
  opacity: 0;
}